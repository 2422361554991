<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Editais</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Prova</th>
                                    <th>Tipo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="test in this.tests" :key="test.id" @click="show(test.id)">
                                    <td>{{ test.id }}</td>
                                    <td>{{ test.name }}</td>
                                    <td>{{ test.type.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <Pagination @updateData="tests = $event" @isBusy="isBusy = $event" :url="url"/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Pagination from '../partials/Pagination.vue'
import { TESTS_INDEX } from '../../constants/api'

export default {

    name: 'Index',
    components: { Pagination },
    data() {
        return  {
            tests: [],
            url: TESTS_INDEX,
        }
    },

    methods: {

        show(id) {
            this.$router.push({ path: `/editais/${id}` })
        }

    }

}
</script>

<style scoped>

    tbody > tr {
        cursor: pointer;
    }

</style>